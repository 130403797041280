var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div'),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"titleSection"},[_c('h3',{attrs:{"id":"vinJump"}},[_vm._v("Plate Range")]),_c('h5',[_vm._v(_vm._s(_vm.plateClass))])]),_c('div',{staticClass:"inputSection threeColumn"},[_c('div',{staticClass:"leftInputs"},[_c('v-text-field',{attrs:{"label":"Quantity"},model:{value:(_vm.plateQuantity),callback:function ($$v) {_vm.plateQuantity=$$v},expression:"plateQuantity"}})],1),_c('div',{staticClass:"middleInputs"},[_c('v-text-field',{attrs:{"disabled":_vm.plateQuantity.length == 0 || _vm.plateQuantity == 0,"error-messages":_vm.plateQuantity.length == 0 || _vm.plateQuantity == 0
              ? 'You must enter a quantity first'
              : '',"label":"Begin Plate #"},on:{"blur":_vm.autoCompletePlate},model:{value:(_vm.beginPlateNo),callback:function ($$v) {_vm.beginPlateNo=$$v},expression:"beginPlateNo"}})],1),_c('div',{staticClass:"rightInputs"},[_c('v-text-field',{attrs:{"disabled":_vm.beginPlateNo.length == 0,"error-messages":_vm.beginPlateNo.length == 0
              ? 'You must enter a begin plate first'
              : '',"label":"End Plate #"},on:{"blur":_vm.fillTransWithPlates},model:{value:(_vm.endPlateNo),callback:function ($$v) {_vm.endPlateNo=$$v},expression:"endPlateNo"}})],1)])]),_c('div',{staticClass:"inputSection threeolumn fullInputWidth"},[_c('div',{staticClass:"leftInputs"},[_c('v-select',{attrs:{"menu-props":"auto","items":_vm.classSelectMap,"label":"Plate Class"},on:{"change":_vm.classSelect},model:{value:(_vm.plateClass),callback:function ($$v) {_vm.plateClass=$$v},expression:"plateClass"}})],1),_c('div',{staticClass:"middleInputs"},[_c('v-select',{attrs:{"label":"Issue Year","items":_vm.issueYearSelectMap,"menu-props":"auto"},model:{value:(_vm.issueYear),callback:function ($$v) {_vm.issueYear=$$v},expression:"issueYear"}})],1),_c('div',{staticClass:"rightInputs"},[_c('v-menu',{ref:"dateMenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Expiration Date","hint":"MM/DD/YYYY format","persistent-hint":"","prepend-icon":"event","readonly":""},model:{value:(_vm.formattedDate),callback:function ($$v) {_vm.formattedDate=$$v},expression:"formattedDate"}},on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"id":"plateExp","value":_vm.expirationDate,"allowed-dates":_vm.isLastDayOfMonth,"no-title":""},on:{"input":function($event){_vm.dateMenu = false;
            _vm.expirationDate = $event;}}})],1)],1)]),_c('center',[_c('v-btn',{attrs:{"color":"blue"},on:{"click":_vm.addPlates}},[_c('span',{staticClass:"white-text"},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Plates ")],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }