export default {
  methods: {
    getCurrentTimestamp() {
      return this.getTimestamp(new Date());
    },
    getTimestamp(date) {
      const offset = date.getTimezoneOffset();
      const o = Math.abs(offset);
      const localOffset =
        (offset < 0 ? "+" : "-") +
        ("00" + Math.floor(o / 60)).slice(-2) +
        ":" +
        ("00" + (o % 60)).slice(-2);
      let dd = date.getDate();
      let mm = date.getMonth() + 1; // January is 0

      const yyyy = date.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }
      let mins = date.getMinutes();
      let secs = date.getSeconds();
      let hours = date.getHours();
      if (mins < 10) {
        mins = "0" + mins;
      }
      if (secs < 10) {
        secs = "0" + secs;
      }
      if (hours < 10) {
        hours = "0" + hours;
      }

      return (
        yyyy +
        "-" +
        mm +
        "-" +
        dd +
        "T" +
        hours +
        ":" +
        mins +
        ":" +
        secs +
        localOffset
      );
    }
  }
};
