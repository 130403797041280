<template>
  <div>
    <h1>Dealer Plates</h1>
    <div class="panel">
      <div class="flex">
        <div class="titleSection">
          <h3 id="vinJump">Dealer</h3>
        </div>
        <div class="inputSection twoColumn">
          <div class="leftInputs">
            <v-text-field
              autofocus
              @blur="completeDealer"
              v-model="transObj.dealerPlateTransactions.dealer.dealerMVCNo"
              label="Dealer Number"
            />

            <v-text-field
              v-model="transObj.dealerPlateTransactions.dealer.name"
              label="Dealer Name"
            />
          </div>
          <div class="rightInputs">
            <!-- <v-text-field label="State License #" /> -->

            <v-text-field
              v-model="transObj.dealerPlateTransactions.dealer.representative"
              label="Representative"
            />
          </div>
        </div>
      </div>

      <br />
      <hr />
      <br />

      <div class="flex">
        <div class="titleSection">
          <h3>Physical Address</h3>
        </div>
        <div class="inputSection twoColumn">
          <div class="rightInputs">
            <vue-smarty-streets
              :uppercase="true"
              :readonly="false"
              :showCounties="true"
              :addressObj="
                transObj.dealerPlateTransactions.dealer.physicalAddress
              "
            ></vue-smarty-streets>
          </div>
        </div>
      </div>

      <br />
      <hr />
      <br />

      <div class="flex">
        <div class="titleSection">
          <h3>Mailing Address</h3>
        </div>
        <div class="inputSection twoColumn">
          <div class="rightInputs">
            <vue-smarty-streets
              :uppercase="true"
              :readonly="false"
              :showCounties="true"
              :addressObj="
                transObj.dealerPlateTransactions.dealer.mailingAddress
              "
            ></vue-smarty-streets>
          </div>
        </div>
      </div>

      <br />
      <hr />
      <br />

      <div>
        <plateRange @addPlates="addPlates($event)"></plateRange>
      </div>

      <br />
      <hr />
      <br />

      <div class="bootstrap">
        <b-table
          :sort-by="'transactionDate'"
          sticky-header="800px"
          :show-empty="true"
          empty-text="No Plates Entered"
          responsive="sm"
          hover
          :fields="tableHeaders"
          head-variant="light"
          sort-icon-left
          :items="transObj.dealerPlateTransactions.transactionPlates"
        >
          <template v-slot:cell(plate.expiresDate)="data">
            {{
              data.item.plate.expiresDate
                .replace("T", " ")
                .replace(/-\d{2}:\d{2}/g, "")
            }}
          </template>
          <template v-slot:cell(remove)="data">
            <v-btn @click="removeItem(data.item)">
              <v-icon color="error">clear</v-icon>
            </v-btn>
          </template>
        </b-table>
      </div>

      <div class="continue">
        <v-btn color="green" @click="continueToCheckout">
          <span class="white-text">Continue</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import vueSmartyStreets from "@/components/nonPageComponents/vue-smarty-streets";
import plateRange from "@/components/dealerPlateComponents/plateRange.vue";
import date from "@/mixins/date.mixin";
export default {
  components: {
    vueSmartyStreets,
    plateRange
  },
  mixins: [date],
  data() {
    return {
      tableHeaders: [
        {
          key: "plate.plateNo",
          label: "Plate #"
        },
        {
          key: "plate.plateClass",
          label: "Class",
          sortable: true
        },
        {
          key: "plate.plateIssueYear",
          label: "Issue Year",
          sortable: true
        },
        {
          key: "plate.expiresDate",
          label: "Expiration Date"
        },
        {
          key: "remove"
        }
      ],
      transObj: {
        dealerPlateTransactions: {
          transactionPlates: [],
          dealer: {
            dealerID: 0,
            name: "",
            dealerMVCNo: "",
            representative: "",
            physicalAddress: {
              nCOAAddressInd: "",
              pOVerifiedInd: "",
              addressTypeCode: "",
              streetNo: "",
              address1: "",
              city: "",
              state: "",
              postalCode: "",
              zIP5: "",
              countyID: parseInt(this.$store.getters.countyId),
              countryCode: ""
            },
            mailingAddress: {
              nCOAAddressInd: "",
              pOVerifiedInd: "",
              addressTypeCode: "",
              streetNo: "",
              address1: "",
              city: "",
              state: "",
              postalCode: "",
              zIP5: "",
              countyID: "",
              countryCode: ""
            }
          }
        },
        transaction: {}
      },
      existingDealerPlates: []
    };
  },
  methods: {
    removeItem(item) {
      const plates = this.transObj.dealerPlateTransactions.transactionPlates;
      for (let i = 0; i < plates.length; i++) {
        if (item.plate.plateNo == plates[i].plate.plateNo) {
          this.transObj.dealerPlateTransactions.transactionPlates.splice(i, 1);
        }
      }
    },
    addPlates(plates) {
      this.transObj.dealerPlateTransactions.transactionPlates =
        this.transObj.dealerPlateTransactions.transactionPlates.concat(plates);
    },
    async continueToCheckout() {
      const masterFlagClassArray = this.existingDealerPlates;
      for (
        let i = 0;
        i < this.transObj.dealerPlateTransactions.transactionPlates.length;
        i++
      ) {
        //this is setting the master flag only on the first plate in a class and only if it doesnt already exist for the dealer
        if (
          !masterFlagClassArray.includes(
            this.transObj.dealerPlateTransactions.transactionPlates[i].plate
              .plateClass
          )
        ) {
          this.transObj.dealerPlateTransactions.transactionPlates[
            i
          ].transaction.isMaster = true;
          masterFlagClassArray.push(
            this.transObj.dealerPlateTransactions.transactionPlates[i].plate
              .plateClass
          );
        }
      }

      const feesObj = await this.$api.getDealerPlateFees(this.transObj);
      if (this._.has(feesObj, "dealerPlateTransactions", "transactionPlates")) {
        feesObj.transaction.fees = [];
        const plates = feesObj.dealerPlateTransactions.transactionPlates;
        for (let i = 0; i < plates.length; i++) {
          for (let j = 0; j < plates[i].transaction.fees.length; j++) {
            feesObj.transaction.fees.push(plates[i].transaction.fees[j]);
          }
        }
        this.$store.commit("transObj", feesObj);
        this.$store.commit("transactionType", "Dealer Plates");
        this.$router.push({ name: "CheckOut" });
      } else {
        this.$root.$emit("push-alert", "You must complete the form.", {
          color: "error"
        });
        return;
      }
    },
    async completeDealer() {
      if (this.transObj.dealerPlateTransactions.dealer.dealerMVCNo == "") {
        return false;
      }
      const dealer = await this.$api.getDealer(
        this.transObj.dealerPlateTransactions.dealer.dealerMVCNo,
        {
          404: () => {
            return false;
          }
        }
      );
      this.existingDealerPlates = [];
      if (this._.has(dealer[0], "plateClassCount")) {
        for (let i = 0; i < dealer[0].plateClassCount.length; i++) {
          this.existingDealerPlates.push(
            dealer[0].plateClassCount[i].plateClass
          );
        }
      }
      this.transObj.dealerPlateTransactions.dealer.name = dealer[0].dealerName;
      this.transObj.dealerPlateTransactions.dealer.dealerID =
        dealer[0].dealerID;
      Object.assign(
        this.transObj.dealerPlateTransactions.dealer.mailingAddres,
        dealer[0].mailingAddress
      );
      Object.assign(
        this.transObj.dealerPlateTransactions.dealer.physicalAddress,
        dealer[0].physicalAddress
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.continue {
  position: fixed;
  right: 25px;
  bottom: 25px;
}
</style>
