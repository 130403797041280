<template>
  <div>
    <div></div>
    <div class="flex">
      <div class="titleSection">
        <h3 id="vinJump">Plate Range</h3>
        <h5>{{ plateClass }}</h5>
      </div>
      <div class="inputSection threeColumn">
        <div class="leftInputs">
          <v-text-field v-model="plateQuantity" label="Quantity" />
        </div>
        <div class="middleInputs">
          <v-text-field
            :disabled="plateQuantity.length == 0 || plateQuantity == 0"
            :error-messages="
              plateQuantity.length == 0 || plateQuantity == 0
                ? 'You must enter a quantity first'
                : ''
            "
            @blur="autoCompletePlate"
            v-model="beginPlateNo"
            label="Begin Plate #"
          />
        </div>
        <div class="rightInputs">
          <v-text-field
            :disabled="beginPlateNo.length == 0"
            :error-messages="
              beginPlateNo.length == 0
                ? 'You must enter a begin plate first'
                : ''
            "
            v-model="endPlateNo"
            @blur="fillTransWithPlates"
            label="End Plate #"
          />
        </div>
      </div>
    </div>

    <div class="inputSection threeolumn fullInputWidth">
      <div class="leftInputs">
        <v-select
          menu-props="auto"
          @change="classSelect"
          v-model="plateClass"
          :items="classSelectMap"
          label="Plate Class"
        />
      </div>
      <div class="middleInputs">
        <v-select
          label="Issue Year"
          v-model="issueYear"
          :items="issueYearSelectMap"
          menu-props="auto"
        />
      </div>
      <div class="rightInputs">
        <v-menu
          ref="dateMenu"
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Expiration Date"
              v-model="formattedDate"
              hint="MM/DD/YYYY format"
              persistent-hint
              prepend-icon="event"
              v-on="on"
              readonly
            ></v-text-field>
          </template>
          <v-date-picker
            id="plateExp"
            :value="expirationDate"
            @input="
              dateMenu = false;
              expirationDate = $event;
            "
            :allowed-dates="isLastDayOfMonth"
            no-title
          ></v-date-picker>
        </v-menu>
      </div>
    </div>

    <center>
      <v-btn color="blue" @click="addPlates">
        <span class="white-text"> <v-icon>mdi-plus</v-icon> Plates </span>
      </v-btn>
    </center>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import date from "@/mixins/date.mixin";
import dayjs from "dayjs";
export default {
  mixins: [date],
  data() {
    return {
      dateMenu: false,
      plateClass: "",
      issueYear: "",
      issueYearArray: [],
      beginPlateNo: "",
      endPlateNo: "",
      plateSubRangeId: "",
      plateQuantity: "",
      plateArray: [],
      usablePlateArray: [],
      expirationDate: new Date(
        new Date().getFullYear() + 1,
        new Date().getMonth() + 1,
        0,
        0,
        0,
        0
      )
    };
  },
  methods: {
    clearFields() {
      this.master = false;
      this.dateMenu = false;
      this.plateClass = "";
      this.issueYear = "";
      this.issueYearArray = [];
      this.beginPlateNo = "";
      this.endPlateNo = "";
      this.plateSubRangeId = "";
      this.plateQuantity = "";
      this.plateArray = [];
      this.usablePlateArray = [];
      this.expirationDate = new Date(
        new Date().getFullYear() + 1,
        new Date().getMonth() + 1,
        0,
        0,
        0,
        0
      );
    },
    addPlates() {
      this.$emit("addPlates", this.usablePlateArray);
      this.clearFields();
    },
    fillTransWithPlates() {
      if (
        this.beginPlateNo == "" ||
        this.endPlateNo == "" ||
        this.plateQuantity == ""
      )
        return false;
      const narrowPlateArray = [];
      const transObjPlateArr = [];
      let foundStart = false;
      for (let i = 0; i < this.plateArray.length; i++) {
        if (this.plateArray[i].plateNo == this.beginPlateNo || foundStart) {
          foundStart = true;
          if (this.plateArray[i].statusCode == "R") {
            narrowPlateArray.push(this.plateArray[i]);
            transObjPlateArr.push({
              transaction: {
                transactionTimestamp: this.getCurrentTimestamp(),
                statusCode: "1",
                countyID: parseInt(this.$store.getters.countyId),
                invoiceNo: this.invoiceNo,
                isVerify: false,
                isMaster: false
              },
              plate: {
                plateNo: this.plateArray[i].plateNo,
                plateClass: this.plateArray[i].classCode,
                plateIssueYear: this.plateArray[i].issueYear,
                expiresDate: dayjs(this.expirationDate).toISOString()
              }
            });
          }

          if (
            this.plateArray[i].plateNo == this.endPlateNo ||
            narrowPlateArray.length == this.plateQuantity
          ) {
            break;
          }
        }
      }
      if (narrowPlateArray.length != this.plateQuantity) {
        this.$root.$emit(
          "push-alert",
          "There are not enough plates in the selected subrange for the quantity specified",
          { color: "error" }
        );
        return;
      }

      this.usablePlateArray = transObjPlateArr;
    },
    isLastDayOfMonth(date) {
      const dateParts = date.split("-");

      const today = new Date(
        dateParts[0],
        parseInt(dateParts[1]) - 1,
        dateParts[2]
      );

      const nextDay = new Date(
        dateParts[0],
        parseInt(dateParts[1]) - 1,
        dateParts[2]
      );
      nextDay.setDate(nextDay.getDate() + 1);

      return nextDay.getMonth() !== today.getMonth();
    },
    async autoCompletePlate() {
      if (this.beginPlateNo == "") return;

      const plateInfo = await this.$api.subRangePlateSearch(this.beginPlateNo, {
        404: () => {
          return false;
        }
      });

      if (typeof plateInfo != "object") return;
      this.plateSubRangeId = plateInfo[0].inventorySubRangeID;
      this.plateClass = plateInfo[0].plateClassCode;
      this.classSelect();
      this.issueYear = plateInfo[0].issueYear;
      await this.loadPlateArray();
    },
    async loadPlateArray() {
      if (this.plateSubRangeId == "") return false;

      this.plateArray = await this.$api.subRangeAvailability(
        this.plateSubRangeId
      );
    },
    classSelect(clearYear = true) {
       
      let platecheck = "";
      this.$emit("classSelect", this.plateClass);
      platecheck = this.plateClass;
      if (platecheck === "") {
       this.issueYearArray = "";
      } else {
        for (let i = 0; i <this.plateClasses.length; i++) {
          if (this.plateClasses[i].plateClassCode == platecheck) {
            const issueYears =this.plateClasses[i].issueYear;
           this.issueYearArray = Object.keys(issueYears).map(x =>
              parseInt(issueYears[x])
            );
            this.$forceUpdate(); //this is needed since we are replacing the whole array here
            break;
          }
        }
      }
      if (clearYear) {
        this.issueYear = "";
      }
    }
  },
  computed: {
    ...mapGetters({
      inventoryPlateClasses: "inventoryPlateClasses",
      plateClasses: "plateClasses",
      countiesArray: "counties",
      invoiceNo: "invoiceNo"
    }),
    relevantPlateClasses() {
      return this.plateClasses;
    },
    classSelectMap() {
      return Object.keys(this.plateClasses).map(key => {
        return {
          text:
            this.plateClasses[key].plateClassCode +
            " - " +
            this.plateClasses[key].plateClassDescription,
          value: this.plateClasses[key].plateClassCode
        };
      });
    },
    issueYearSelectMap() {
      return Object.keys(this.issueYearArray).map(key => {
        return {
          text: this.issueYearArray[key],
          value: this.issueYearArray[key]
        };
      });
    },
    formattedDate() {
      const date = new Date(
        new Date(
          new Date().getFullYear() + 1,
          new Date().getMonth() + 1,
          0,
          0,
          0,
          0
        )
      ).toISOString();
      const truncatedDate = date.substr(0, 10);

      const dateParts = truncatedDate.split("-");

      return dateParts[1] + "/" + dateParts[2] + "/" + dateParts[0];
    }
  },
  watch: {
    plateQuantity() {
      if (this.plateQuantity == "") {
        this.beginPlateNo = "";
        this.endPlateNo = "";
      }
    },
    beginPlateNo() {
      if (this.beginPlateNo == "") {
        this.endPlateNo = "";
      }
    }
  }
};
</script>
